import React, { useEffect, useRef } from "react";
import { BookingStatus, BookingModel } from "../../data/models/BookingModel";
import { Role } from "../../data/models/Roles";
import { RowActions } from "../../data/models/RowActions";
import BookingUtils from "../../utils/BookingUtils";
import RowActionButtons from "./RowActionButtons";

const getStatusClass = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.Cancelled:
      return " cancelled";
    default:
      return "";
  }
};

interface BookingsTableItemProps {
  booking: BookingModel,
  rowActions?: RowActions,
  colClasses: Array<string>,
  className: string,
  focus: boolean,
  role: Role
}

const BookingsTableItem: React.FC<BookingsTableItemProps> = ({ booking, rowActions, colClasses, className, focus, role }) => {
  const myRef = useRef<null | HTMLDivElement>(null)
  const statusClass = booking.status ? getStatusClass(booking.status) : "";
  const needsStarterPack = BookingUtils.getsStarterPack(booking);
  const starterPack = needsStarterPack ? "YES" : "NO";
  const reminder = BookingUtils.getReminder(booking, needsStarterPack);
  const focusClass = focus ? " border border-4 border-success" : ""
  const rowClass = `row mx-0 ${className}${focusClass}`

  useEffect(() => {
    if (myRef && myRef.current)
      myRef.current.scrollIntoView()
  }, [focus])

  return (
    <div ref={myRef} className={rowClass}>
      <div className={colClasses[0]}>
        <div className="d-block d-md-none fw-bold">Check In*</div>
        {booking["check in date"]}
      </div>
      <div className={colClasses[1]}>
        <div className="d-block d-md-none fw-bold">Check Out*</div>
        {booking["check out date"]}
      </div>
      <div className={colClasses[2]}>
        <div className="d-block d-md-none fw-bold">Guest Name</div>
        {booking.name}
      </div>
      <div className={colClasses[3]}>
        <div className="d-block d-md-none fw-bold">Phone #</div>
        {booking.phone}
      </div>
      <div className={colClasses[4]}>
        <div className="d-block d-md-none fw-bold">Starter Pack</div>
        {starterPack}
      </div>
      <div className={colClasses[5]}>
        <div className="d-block d-md-none fw-bold">Confirmation #</div>
        {booking.pmConfirmation}
      </div>
      <div className={colClasses[6]}>
        <div className="d-block d-md-none fw-bold">Reminder/Notes</div>
        {reminder}
      </div>
      {role === Role.Admin && <RowActionButtons className={colClasses[7]} booking={booking} rowActions={rowActions} />}
    </div>
  );
};

export default BookingsTableItem;
