import { BookingModel } from "../data/models/BookingModel"

export interface ICheckinCheckoutDates {
    checkIn: Date,
    checkOut: Date
}

export default {
    getCheckinCheckoutDates(booking: BookingModel): ICheckinCheckoutDates {
        return {
            checkIn: new Date(booking["check in date"]),
            checkOut: new Date(booking["check out date"])
        }
    },
    
    getsStarterPack(booking: BookingModel) {
        if(booking.requestedStarterPack) return true;
        
        let millisInDay = 1000*3600*24
        let dates = this.getCheckinCheckoutDates(booking)
        let diff = dates.checkOut.getTime() - dates.checkIn.getTime()
        return diff > millisInDay * 5
    },
    getReminder(booking: BookingModel, needsStarterPack: Boolean) {
        if(booking.reminder) return booking.reminder;
        if(!needsStarterPack)
            return "Reminder: Please leave out one of our trash bag/dish tab bundles we have in the cleaner's closet"
    }
}