import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import PmBookingsList from "./pages/PmBookingsList";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import AddConfirmation from "./pages/AddConfirmation";
import "@aws-amplify/ui-react/styles.css";
import "./app.scss";
import "./custom.scss";
import AdminRedirect from "./pages/AdminRedirect";
import AdminRouter from "./pages/admin/AdminRouter";
import { Role } from "./data/models/Roles";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/pm/:key/confirm/:id" element={<AddConfirmation />} />
        <Route path="/pm/:key" element={<PmBookingsList />} />
        <Route path="/" element={<AdminRedirect />} />
        <Route path="/admin/*" element={<AdminRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
