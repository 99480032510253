import { Role } from "./models/Roles";
import awsExports from "../aws-exports";
import { BookingModel } from "./models/BookingModel";

export const fetchBookings = async (role: Role, key?: string) => {
    const uri = awsExports.BOOKING_API + (role === Role.Admin ? "" : "/pm") + "/bookings";
    let headers: any;
    if (role === Role.Admin)
        headers = { 'Authorization': key ?? "" };
    else
        headers = { 'X-Api-Key': key ?? "" };

    return await fetch(
        uri,
        { headers: headers }
    ).then((res) => res.json());
}

export const createBooking = async (booking: BookingModel, key: string) => {
    const uri = awsExports.BOOKING_API + "/bookings";
    return await fetch(
        uri,
        {
            method: "POST",
            headers: { 'Authorization': key },
            body: JSON.stringify(booking)
        }
    ).then((res) => res.json());
}

export const deleteBooking = async (confirmation: string, key: string) => {
    const uri = awsExports.BOOKING_API + "/bookings/" + confirmation;
    return await fetch(
        uri,
        {
            method: "DELETE",
            headers: { 'Authorization': key }
        }
    );
}

export interface IUpdateBookingRequest {
    key: string,
    booking: BookingModel;
}


export const updateBooking = async (
    request: IUpdateBookingRequest
): Promise<BookingModel> => {
    const uri = awsExports.BOOKING_API + "/bookings/" + request.booking.confirmation;
    const trimmedBooking: any = request.booking;
    delete trimmedBooking.confirmation;
    const response = await fetch(
        uri,
        {
            method: "PUT",
            headers: { 'Authorization': request.key },
            body: JSON.stringify(trimmedBooking)
        }
    );
    return await response.json();
}


export interface ISetPmConfirmationRequest {
    key?: string,
    airbnbConfirmation: string;
    pmConfirmation: string;
}

export const setPmConfirmation = async (
    variables: ISetPmConfirmationRequest
): Promise<string> => {
    console.log(`Calling patch with API key: ${variables.key}`)
    const key = variables.key ?? ""
    const response = await fetch(
        `https://kwhwbywo9k.execute-api.us-east-1.amazonaws.com/prod/pm/bookings/${variables.airbnbConfirmation}`,
        {
            method: "PATCH",
            headers: { 'x-api-key': key },
            body: JSON.stringify(variables.pmConfirmation),
        }
    );
    return (await response.json())["pmConfirmation"];
};