import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isProd = process.env.NODE_ENV === 'production';
const domain = isProd ? "https://bookings.topfloorrentals.com/" : "http://localhost:3000/"
// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
  oauth: {
    domain: "topfloorrentals.auth.us-east-1.amazoncognito.com",
    scope: [
      "email",
      "openid",
      "phone"
    ],
    redirectSignIn: domain,
    redirectSignOut: domain,
    responseType: "code", // or 'token', depending on the provider
  },
  API: {
    endpoints: [
      {
        name: "BookingsAPI",
        endpoint: "https://kwhwbywo9k.execute-api.us-east-1.amazonaws.com/prod"
      },
    ]
  }
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
