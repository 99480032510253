import React, { useEffect } from 'react'
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  let navigate = useNavigate();
  
  useEffect(() => {
    Auth.currentSession()
      .then(() => navigate("/admin"))
      .catch(err => {});
  }, [navigate])



  return (
    <Authenticator variation="modal" hideSignUp={true} socialProviders={['google']}>
      {() => <></>}
    </Authenticator>
  )
}

export default Admin