import {
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import React, { FormEvent, useEffect, useState } from "react";
import { useParams, useNavigate, redirect } from "react-router-dom";
import { IUpdateBookingRequest, fetchBookings, updateBooking } from "../../data/api";
import { BookingModel, BookingStatus } from "../../data/models/BookingModel";
import { Role } from "../../data/models/Roles";
import BookingUtils from "../../utils/BookingUtils";
import { ReactElement } from "react";

interface IEditBookingProps {
    accessToken: string;
}

const EditBooking: React.FC<IEditBookingProps> = ({ accessToken }) => {
    let { id } = useParams();
    const { isLoading, error, data } = useQuery(["bookings"], () => fetchBookings(Role.Admin, accessToken));
    const queryClient = useQueryClient();
    const {
        isLoading: isSaving,
        isError,
        mutate,
    } = useMutation<BookingModel, Error, IUpdateBookingRequest>({
        mutationFn: updateBooking,
        onSuccess:
            () => {
                setSaved(true)
                queryClient.invalidateQueries({ queryKey: ['bookings'] })
                console.log("saved")
            },
    });
    
    let navigate = useNavigate();
    const [confNumber, setConfNumber] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [saved, setSaved] = useState(false);

    const [booking, setBooking] = useState<BookingModel>({
        guests: "",
        confirmation: "",
        pmConfirmation: "",
        "check in date": "",
        "check out date": "",
        friendlyName: "",
        name: "",
        status: BookingStatus.Active,
        phone: "",
        requestedStarterPack: false,
        reminder: "",
        "thread Id": "",
    })

    let bookingData = (data as Array<BookingModel>)?.find(
        (booking) => booking.confirmation === id
    );

    useEffect(() => {
        if (bookingData) {
            setBooking({ ...bookingData })
        }
    }, [bookingData])

    if(saved) navigate("/admin")
    if (isLoading) return <p>Loading</p>;
    if (error) return <p>An error occurred</p>;
    if (!booking) return <p>Unknown booking</p>;

    let needsStarterPack = BookingUtils.getsStarterPack(booking);
    let starterPack = needsStarterPack ? "YES" : "NO";
    let reminder = BookingUtils.getReminder(booking, needsStarterPack);
    let showSaving = isSaving || saved

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!booking) {
            setErrorMsg("Booking not loaded!");
            return;
        }
        mutate({
            key: accessToken,
            booking: booking
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any >) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setBooking({ ...booking, [name]: newValue });
    }

    return (
        <div className="container-sm p-5">
            <div className="shadow rounded overflow-hidden">
                <div className="row px-4 bg-primary text-white">
                    Edit Booking
                </div>
                <div className="row">
                    <div className="col">
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className={"row px-4"}>
                                <div>
                                    <div><label htmlFor="check in date" className="fw-bold">Check In:</label></div>
                                    <input type="date" name="check in date" value={booking["check in date"]} onChange={handleChange} />
                                </div>
                                <div>
                                    <div><label htmlFor="check out date" className="fw-bold">Check Out*</label></div>
                                    <input type="date" name="check out date" value={booking["check out date"]} onChange={handleChange} />
                                </div>
                                <div>
                                    <div><label htmlFor="name" className="fw-bold">Guest Name</label></div>
                                    <input type="text" name="name" value={booking.name ?? ""} onChange={handleChange} />
                                </div>
                                <div>
                                    <div><label htmlFor="phone" className="fw-bold">Phone #</label></div>
                                    <input type="phone" name="phone" value={booking.phone ?? ""} onChange={handleChange} />
                                </div>
                                <div>
                                    <div><label htmlFor="pmConfirmation" className="fw-bold">Confirmation code</label></div>
                                    <input type="text" name="pmConfirmation" value={booking.pmConfirmation ?? ""} onChange={handleChange} />
                                </div> 
                                <div>
                                    <div><label htmlFor="requestedStarterPack" className="fw-bold">Starter Pack</label></div>
                                    <input type="checkbox" name="requestedStarterPack" checked={booking.requestedStarterPack ?? false} onChange={handleChange} />
                                </div>
                                <div>
                                    <div><label htmlFor="reminder" className="fw-bold">Reminder/Notes</label></div>
                                    <textarea name="reminder" rows={5} cols={40} onChange={handleChange} value={reminder} />
                                </div>
                                <div>
                                    {!showSaving && (

                                        <div>
                                            <button type="submit">Save</button>
                                            <button type="button" onClick={() => navigate("/admin")}>Cancel</button>
                                        </div>)}
                                    {errorMsg !== "" && errorMsg}
                                    {isError && <>An error occured during save</>}
                                    {showSaving && <>Saving...</>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditBooking;
function mutate(arg0: { key: any; airbnbConfirmation: string; pmConfirmation: string; }) {
    throw new Error("Function not implemented.");
}

