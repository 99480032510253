import React from 'react'
import { Button } from 'react-bootstrap'
import { BookingModel } from '../../data/models/BookingModel'
import { RowActions } from '../../data/models/RowActions'

interface RowActionButtonsProps {
    className: string,
    booking: BookingModel,
    rowActions?: RowActions
}

const RowActionButtons: React.FC<RowActionButtonsProps> = ({ className, booking, rowActions }) => {
    const buttons = rowActions?.actions.map(action =>
        <Button key={action} onClick={() => rowActions.onAction(action, booking)}>{action}</Button>
    )
    return (
        <div className={className}>
            {buttons}
        </div>
    )
}

export default RowActionButtons