import React from 'react'
import { Authenticator } from "@aws-amplify/ui-react";
import Amplify, { Auth } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminBookingsList from './AdminBookingsList';
import Header from './Header';
import EditBooking from './EditBooking';

const notUser = <>Invalid user!</>

const AdminRouter = () => {
  return (
    <Authenticator variation="modal" hideSignUp={true} socialProviders={['google']}>
      {({ signOut, user }) => {
        const email = user?.getSignInUserSession()?.getIdToken().payload.email
        const accessToken = user?.getSignInUserSession()?.getIdToken().getJwtToken()
        if (!email || !accessToken || !signOut) return notUser
        

        return (
          <div>
            <Header userEmail={email} logout={() => signOut()} />
            <Routes>
              <Route path="/" element={<AdminBookingsList accessToken={accessToken} />} />
              <Route path="/edit/:id" element={<EditBooking accessToken={accessToken} />} />
            </Routes>
          </div>
        )
      }}
    </Authenticator>
  )
}

export default AdminRouter