import React from "react";
import { useParams } from "react-router-dom";
import BookingsTableItems from "../components/BookingsTable/BookingsTableItems";
import { Role } from "../data/models/Roles";

const PmBookingsList: React.FC = () => {
  let { key } = useParams();
  const colClasses = [
    "col-md-2 col-xl-1 text-nowrap", // Checkin
    "col-md-2 col-xl-1 text-nowrap", // checkout
    "col-md-2", // name
    "col", // phone
    "col-md-1", // starter pack
    "col-md-1", // conf
    "col", // reminder
  ];

  if (key === undefined) return <></>;
  return (
    <div className="container-xxl">
      <h1> Bookings</h1>
      *Standard Check in/Check out is 4pm, 10am unless specificied otherwise in
      the notes
      <div className="shadow rounded overflow-hidden">
        <div className="d-none d-md-block">
          <div className="row mx-0 py-1 bg-primary text-white">
            <div className={colClasses[0]}>Check In*</div>
            <div className={colClasses[1]}>Check Out*</div>
            <div className={colClasses[2]}>Name</div>
            <div className={colClasses[3]}>Phone #</div>
            <div className={colClasses[4]}>Starter Pack?</div>
            <div className={colClasses[5]}>Conf#</div>
            <div className={colClasses[6]}>Reminder/Notes</div>
          </div>
        </div>
        <BookingsTableItems colClasses={colClasses} role={Role.PropertyManager} apiKey={key} />
      </div>
    </div>
  );
};

export default PmBookingsList;
