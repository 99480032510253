export enum BookingStatus {
  Active = 1,
  Booked = 2,
  Cancelled = 3,
}

export interface BookingModel {
  guests: string;
  confirmation: string;
  pmConfirmation: string;
  "thread Id": string;
  "check in date": string;
  "check out date": string;
  friendlyName: string;
  name: string;
  status: BookingStatus;
  phone: string;
  requestedStarterPack: boolean;
  reminder: string;
}
