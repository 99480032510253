import React from "react";
import { BookingModel } from "../../data/models/BookingModel";
import BookingsTableItem from "./BookingsTableItem";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { fetchBookings } from "../../data/api";
import { Role } from "../../data/models/Roles";
import { RowActions } from "../../data/models/RowActions";

interface BookingsTableItemsProps {
  colClasses: Array<string>,
  rowActions?: RowActions,
  role: Role,
  apiKey: string,
  doRefresh?: boolean,
}

const BookingsTableItems: React.FC<BookingsTableItemsProps> = ({ colClasses, rowActions, role, apiKey, doRefresh }) => {
  const { isLoading, error, data, refetch } = useQuery(["bookings"], () => fetchBookings(role, apiKey));
  const [searchParams] = useSearchParams();
  const focusConfirmation = searchParams.get("c")

  React.useEffect(() => {
    refetch();
  }, [doRefresh, refetch]);

  if (isLoading) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;
  let today = new Date().toJSON().slice(0, 10);

  let oddRow = true;
  let dataItems = (data as Array<BookingModel>)
    .filter((a) => a["check out date"] >= today)
    .sort((a, b) => a["check in date"] < b["check in date"] ? -1 : a["check in date"] > b["check in date"] ? 1 : 0 )
    .map((booking) => {
      oddRow = !oddRow;
      return (
        <BookingsTableItem
          className={oddRow ? "bg-primary-subtle" : ""}
          key={booking.confirmation}
          booking={booking}
          colClasses={colClasses}
          focus={focusConfirmation === booking.confirmation}
          rowActions={rowActions}
          role={role}
        />
      );
    });
  return <>{dataItems}</>;
};

export default BookingsTableItems;
