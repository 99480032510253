import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { AmplifyUser } from '@aws-amplify/ui'
import { Amplify, API } from 'aws-amplify';
import PmBookingsList from '../PmBookingsList';
import { Role } from '../../data/models/Roles';
import BookingsTableItems from '../../components/BookingsTable/BookingsTableItems';
import { RowActions } from '../../data/models/RowActions';
import { BookingModel } from '../../data/models/BookingModel';
import { deleteBooking } from '../../data/api';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useNavigate } from 'react-router-dom';

interface AdminBookingsListProps {
  accessToken: string
}

const AdminBookingsList: React.FC<AdminBookingsListProps> = ({ accessToken }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState({ show: false, booking: {} as BookingModel })
  const [doRefresh, setDoRefresh] = useState(false)
  const navigate = useNavigate()


  const colClasses = [
    "col-md-2 col-xl-1 text-nowrap", // Checkin
    "col-md-2 col-xl-1 text-nowrap", // checkout
    "col-md-2", // name
    "col", // phone
    "col-md-1", // starter pack
    "col-md-1", // conf
    "col", // reminder
    "col-md-1", // edit/delete
  ];

  const hideModal = () => {
    setShowDeleteConfirm({ show: false, booking: {} as BookingModel })
  }

  const actions: RowActions = {
    actions: ["edit", "delete"],
    onAction: useCallback((action: string, booking: BookingModel) => {
      switch (action) {
        case "edit":
          return navigate("/admin/edit/" + booking.confirmation)
          break
        case "delete":
          setShowDeleteConfirm({ show: true, booking: booking })
          break
      }
    }, [])
  }

  const forceRefresh = () => {
    setDoRefresh(!doRefresh)
  }

  return (
    <div className="container-xxl">
      <ConfirmationModal
        show={showDeleteConfirm.show}
        title="Delete Booking"
        body="Are you sure you want to delete this booking?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={() => { deleteBooking(showDeleteConfirm.booking.confirmation, accessToken); hideModal(); forceRefresh() }}
        onCancel={() => { hideModal() }}
      />
      <h1> Bookings</h1>
      *Standard Check in/Check out is 4pm, 10am unless specificied otherwise in
      the notes
      <div className="shadow rounded overflow-hidden">
        <div className="d-none d-md-block">
          <div className="row mx-0 py-1 bg-primary text-white">
            <div className={colClasses[0]}>Check In*</div>
            <div className={colClasses[1]}>Check Out*</div>
            <div className={colClasses[2]}>Name</div>
            <div className={colClasses[3]}>Phone #</div>
            <div className={colClasses[4]}>Starter Pack?</div>
            <div className={colClasses[5]}>Conf#</div>
            <div className={colClasses[6]}>Reminder/Notes</div>
            <div className={colClasses[7]}>Actions</div>
          </div>
        </div>
        <BookingsTableItems
          colClasses={colClasses}
          role={Role.Admin}
          apiKey={accessToken}
          rowActions={actions}
          doRefresh={doRefresh} />
      </div>
    </div>
  );
}

export default AdminBookingsList