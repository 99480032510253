import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { FormEvent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchBookings, ISetPmConfirmationRequest, setPmConfirmation } from "../data/api";
import {
  BookingModel
} from "../data/models/BookingModel";
import { Role } from "../data/models/Roles";
import BookingUtils from "../utils/BookingUtils";

const AddConfirmation: React.FC = () => {
  let { id, key } = useParams();
  const { isLoading, error, data } = useQuery(["bookings"], () => fetchBookings(Role.PropertyManager, key));
  const queryClient = useQueryClient();
  const {
    isLoading: isSaving,
    isError,
    mutate,
  } = useMutation<string, Error, ISetPmConfirmationRequest>({
    mutationFn: setPmConfirmation,
    onSuccess:
      () => {
        setSaved(true)
        queryClient.invalidateQueries({ queryKey: ['bookings'] })
      },
  });

  let navigate = useNavigate();
  const [confNumber, setConfNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [saved, setSaved] = useState(false);

  if (isLoading) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;
  let booking = (data as Array<BookingModel>).find(
    (booking) => booking.confirmation === id
  );
  if (!booking) return <p>Unknown booking</p>;

  if ((booking.pmConfirmation ?? "") !== "") navigate(`/pm/${key}/?c=${booking.confirmation}`);

  let needsStarterPack = BookingUtils.getsStarterPack(booking);
  let starterPack = needsStarterPack ? "YES" : "NO";
  let reminder = BookingUtils.getReminder(booking, needsStarterPack);
  let showSaving = isSaving || saved

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!booking) {
      setErrorMsg("Booking not loaded!");
      return;
    }
    if (confNumber.length < 3)
      setErrorMsg("Confirmation number must be at least 3 characters");
    else {
      mutate({
        key: key,
        airbnbConfirmation: booking.confirmation,
        pmConfirmation: confNumber
      });
    }
  };

  return (
    <div className="container-sm p-5">
      <div className="shadow rounded overflow-hidden">
        <div className="row px-4 bg-primary text-white">
          Please confirm booking
        </div>
        <div className="row">
          <div className="col">
            <div className={"row px-4"}>
              <div>
                <div className="fw-bold">Check In:</div>
                {booking["check in date"]}
              </div>
              <div>
                <div className="fw-bold">Check Out*</div>
                {booking["check out date"]}
              </div>
              <div>
                <div className="fw-bold">Guest Name</div>
                {booking.name}
              </div>
              <div>
                <div className="fw-bold">Phone #</div>
                {booking.phone ?? "Unknown"}
              </div>
              <div>
                <div className="fw-bold">Starter Pack</div>
                {starterPack}
              </div>
              <div>
                <div className="fw-bold">Reminder/Notes</div>
                {reminder}
              </div>
            </div>
          </div>
          <div className="col d-flex align-items-center">
            <div>
              {!showSaving && (
                <form onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="pmConfirmation">
                    Please enter confirmation code:{" "}
                  </label>
                  <input
                    id="pmConfirmation"
                    value={confNumber}
                    onChange={(e) => setConfNumber(e.target.value)}
                  ></input>
                  <div>
                    <button type="submit">Confirm!</button>
                  </div>
                  {errorMsg !== "" && errorMsg}
                  {isError && <>An error occured during save</>}
                </form>
              )}
              {showSaving && <>Saving...</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddConfirmation;
